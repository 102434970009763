import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
const StuckPixelFix = ({ startRepair }) => {
  return (
    <div className="px-3 prose-sm prose sm:prose lg:prose-lg xl:prose-xl md:px-0">
      <p className="lead">
        Cliquez sur le bouton <code>Réparer</code> et votre écran deviendra noir
        et vous verrez un carré avec des dégradés. Faites glisser ce carré et
        amenez-le sur vos pixels bloqués et attendez comme ça pendant 10 à 15
        minutes.
      </p>
      <blockquote>
        N'oubliez pas d'ajuster vos paramètres d'alimentation pour que l'écran
        ne s'éteigne pas.
      </blockquote>
      <p>
        Vous pouvez appuyer sur{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        pour terminer le processus de réparation des pixels bloqués et quitter.
      </p>
      <div className="text-center">
        <PurpleSolidButton text="Réparer" onClick={startRepair} />
      </div>
    </div>
  )
}
export default memo(StuckPixelFix)
